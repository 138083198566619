<template>
    <div class="dashboard">

        <div class="dash-wrap"> 

            <div class="sidebar">
                <filter-supplier-service @filter="filterDashboard"></filter-supplier-service>
            </div>
            
            <div class="content">
                <div class="widget">
                    <div class="subtitle">Servicios - Proveedor - Costo</div>
                    <bar-chart :key="`line-${key}`" :labels="pieLabels" axis="y"
                        :dataChart="pieValues" :height="350"></bar-chart>
                </div>
                <div class="widget">
                    <div class="subtitle">Servicios - Proveedor - Markup</div>
                    <bar-chart :key="`line-${key}`" :labels="markupArr.labels"
                        :dataChart="markupArr.values" :height="350"></bar-chart>
                </div>
                <!-- <div class="widget gap-2">
                
                </div> -->

                <div class="widget gap-2">

                    <div class="services summary">
                        <div class="item">
                            <div class="count">{{ $n(totalCost, 'currency') }}</div>
                            <div class="name">Total Cost</div>
                        </div>
                        <div class="item">
                            <div class="count">{{ $n(totalRetail, 'currency') }}</div>
                            <div class="name">Total Retail</div>
                        </div>
                        <div class="item">
                            <div class="count">{{ $n(totalMarkup, 'currency') }}</div>
                            <div class="name">Total Markup</div>
                        </div>
                        <div class="item">
                            <div class="count">{{ totalBookings }}</div>
                            <div class="name">Bookings</div>
                        </div>
                    </div>

                    <div class="table-wrapper with-border">
                        <div class="subtitle">Top Servicios</div>
                        <vs-table :data="dataKpis">
                            <template slot="thead">
                                <vs-th sort-key="Service">Service</vs-th>
                                <vs-th sort-key="SupplierCode">Supp Code</vs-th>
                                <vs-th sort-key="TOTA_COST">Cost</vs-th>
                                <vs-th sort-key="TOTAL_RETAIL">Retail</vs-th>
                                <vs-th sort-key="MARKUP">MARKUP</vs-th>
                                <vs-th sort-key="QTY_BOOKINGS">Bookings</vs-th>
                                <vs-th sort-key="QTY_DAYS">Days</vs-th>
                                <vs-th sort-key="PROM_DAYS_PER_BOOK">Promedio</vs-th>
                            </template>
                            <template slot-scope="{ data }">
                                <template :data="tr"  v-for="(tr, indextr) in data">
                                    <vs-tr :key="indextr">
                                        <vs-td :data="data[indextr].year">     {{ data[indextr].Service }} </vs-td>
                                        <vs-td :data="data[indextr].SupplierCode">     {{ data[indextr].SupplierCode }} </vs-td>
                                        <vs-td :data="data[indextr].TOTA_COST">     {{ $n(data[indextr].TOTA_COST, 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].TOTAL_RETAIL">     {{ $n(data[indextr].TOTAL_RETAIL, 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].MARKUP">     {{ $n(data[indextr].MARKUP, 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].QTY_BOOKINGS">     {{ data[indextr].QTY_BOOKINGS }} </vs-td>
                                        <vs-td :data="data[indextr].QTY_DAYS">     {{ data[indextr].QTY_DAYS }} </vs-td>
                                        <vs-td :data="data[indextr].PROM_DAYS_PER_BOOK">     {{ data[indextr].PROM_DAYS_PER_BOOK }} </vs-td>
                                        
                                    </vs-tr>
                                </template>
                            </template>
                        </vs-table>
                    </div>   
                </div>

                <div class="widget gap-2">
                    <div class="subtitle">Costo - Retail - Markup - Servicio</div>
                    <bar-chart :key="key" :labels="suppLabels2" title="s" 
                        :dataChart="suppliersTopKpis" :height="350" v-if="suppliersTopKpis.length > 0 && !showLineChart"></bar-chart>
                </div>

                <div class="widget gap-2">
                    <div class="subtitle">Costo Servicio - Año</div>
                    <bar-chart :key="key" :labels="suppLabels" title="s" 
                        :dataChart="suppValies" :height="350" v-if="kpis.length > 0 && !showLineChart"></bar-chart>
                </div>
                <div class="gap-2 widget">
                    <div class="subtitle">Comparativa Mensual</div>
                    <div class="table-wrapper with-border">
                        <vs-table :data="kpis">
                            <template slot="thead">
                                <vs-th sort-key="year" class="center">Año</vs-th>
                                <vs-th sort-key="ServiceName">Service</vs-th>
                                <vs-th sort-key="January">Enero</vs-th>
                                <vs-th sort-key="February">Febrero</vs-th>
                                <vs-th sort-key="March">Marzo</vs-th>
                                <vs-th sort-key="April">Abril</vs-th>
                                <vs-th sort-key="May">Mayo</vs-th>
                                <vs-th sort-key="June">Junio</vs-th>
                                <vs-th sort-key="July">Julio</vs-th>
                                <vs-th sort-key="August">Agosto</vs-th>
                                <vs-th sort-key="September">Septiembre</vs-th>
                                <vs-th sort-key="October">Octubre</vs-th>
                                <vs-th sort-key="November">Noviembre</vs-th>
                                <vs-th sort-key="December">Diciembre</vs-th>
                                <vs-th sort-key="TOTAL">Total</vs-th>
                            </template>
                            <template slot-scope="{ data }">
                                <template :data="tr"  v-for="(tr, indextr) in data">
                                    <vs-tr :key="indextr">
                                        <vs-td :data="data[indextr].year" class="freeze">     {{ data[indextr].year }} </vs-td>
                                        <vs-td :data="data[indextr].SRVCODE" class="freeze">     {{ data[indextr].ServiceName }} </vs-td>
                                        <vs-td :data="data[indextr].January">  {{ $n(data[indextr].January , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].February"> {{ $n(data[indextr].February, 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].March">    {{ $n(data[indextr].March   , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].April">    {{ $n(data[indextr].April   , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].May">      {{ $n(data[indextr].May     , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].June">     {{ $n(data[indextr].June    , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].July">     {{ $n(data[indextr].July    , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].August">   {{ $n(data[indextr].August  , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].September">{{ $n(data[indextr].September, 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].October">  {{ $n(data[indextr].October , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].November"> {{ $n(data[indextr].November, 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].December"> {{ $n(data[indextr].December, 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].total">    {{ $n(data[indextr].total   , 'currency') }} </vs-td>
                                    
                                    </vs-tr>
                                </template>
                            </template>
                        </vs-table>
                    </div>           
                </div>
            </div>
        </div>  

        <modal :show="modalShow" :dark="true" title="Código Acceso">
            <div slot="modal-body">
                <div class="modal-content" style="padding: 35px 35px 15px">
                    <p>Por favor ingresar el código de acceso</p>
                    <br>
                    <vs-input v-model="codeAccess"  :danger="errorCode" danger-text="Código incorrecto" v-on:keyup.enter="validAccess()"/>
                </div>
            </div>
            <div slot="modal-footer">
                <div class="modal-footer">
                    <vs-button color="primary" type="border" @click="validAccess()" :disabled="disabled">Ingresar</vs-button>
                </div>
            </div>
        </modal>

    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import BookingService from '@/services/BookingService'
import modal from "@/components/Modals/Basic.vue";
const  { mapActions: mapAppSettingActions } = createNamespacedHelpers('appSetting')

import filterSupplierService from '@/components/Filters/filterSupplierService'
import barChart  from '@/components/Charts/Bar'

export default {
    name: "BookingsView",
    components: {
        modal,
        barChart,
        filterSupplierService
    },
    data () {
        return {
            key: 1,
            date: "",
            modalShow: true,
            codeAccess: "",
            errorCode: false,
            disabled: false,
            kpis: [],
            showLineChart: false,
            colors: ["#00ccb3","#1a75d6","#fac71e","#905bff","#f9893e","#fc573b","#df41b6"],
            suppliers: [],
            openSuggestions: true,
            suppliersKpis: [],
            suppLabels: [],
            suppValies: [],
            suppliersTopKpis: [],
            suppLabels2: [],
            suppValies2: [],
            years:[],
            srvTypes: [],
            srvCodes: [],
            services_types: [],
            classSrv: [],
            classChoose: [],
            classCodes: [],
            dataKpis: [],
            filter: {},
            pieLabels: [],
            pieValues: [],
            markupArr: [],
            totalMarkup: 0,
            totalRetail: 0,
            totalCost: 0,
            totalBookings: 0,
        }
    },
    async created () {
        let code =  JSON.parse(localStorage.getItem('CDG'));
        this.codeAccess = code
        if(this.codeAccess != "" || this.disabled != undefined){
            this.validAccess();
        }
        this.date = {
            start: new Date(),
            end:   new Date(),
        }
        this.getSupplierKpis();
        this.getTopSupplierKpis();

        // this.loadingCharts()
         
    },
    directives: {
        'click-outside': {
            bind: function (el, binding, vnode) {
                console.log(el)
                el.clickOutsideEvent = (event) => {
                    if (!(el === event.target || el.contains(event.target))) {
                        vnode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener("click", el.clickOutsideEvent);
            }
        }
    },
    methods: {
        ...mapAppSettingActions(["LOADING_STATUS"]),
        
        async filterDashboard(options){
            this.filter = options
            await this.getSupplierKpis();
            await this.getTopSupplierKpis();
        },
        async getSupplierKpis(){
            console.log('getSupplierKpis');
            let response = await BookingService.getSupplierKpis({ 
                'groupBy': "SERVICES_YEARS",
                ...this.filter
            });
            console.log({'response': response})
            if((response.status / 100) == 2){
                this.suppliersKpis = response.data.data
            }
            let tempCostKpi = [];
            this.suppLabels = [];
            this.suppValies = [];
            this.suppliersKpis.forEach( kpi => {
                if(!this.suppLabels.includes(kpi.year)) this.suppLabels.push(kpi.year);
                kpi.data.forEach( item => {
                    let total = 0;
                    for (const key in item) {
                        if(!isNaN(parseFloat(item[key]))){
                            total += parseFloat(parseFloat(item[key]));
                        }
                    }
                    tempCostKpi.push({
                        year: kpi.year,
                        total: total,
                        ...item
                    })
                });

            });
            
            this.kpis = tempCostKpi
            const groupBy = (array, property) => array.reduce((grouped, element) => (
                {
                    ...grouped,
                    [element[property]]: [ ...(grouped[element[property]] || []), element]
                }), {}
            )

            let josue = groupBy(this.kpis,'ServiceName')
            // // josue.forEach( item => {
            let counter = 0;
            for (const key in josue) {
                let tempArr = []
                josue[key].forEach(item => {
                   tempArr.push(item.total) 
                });
                this.suppValies.push({
                    data:  tempArr,
                    label: key,
                    backgroundColors: this.colors[counter],
                })
                counter++;
            }
            
        },
        async getTopSupplierKpis(){
            console.log('getSupplierKpis');
            let response = await BookingService.getSupplierKpis({ 
                'groupBy': "TOP_SERVICES",
                ...this.filter
            });
            if((response.status / 100) == 2){
                this.dataKpis =response.data.data
                this.suppliersTopKpis = response.data.data
            }
            
            let topSupplierGraph = this.groupArrBy(this.dataKpis, 'SupplierCode');
            let tempArr = []
            this.pieLabels =[];
            this.markupArr.labels = [];
            let tempArrMarkup = []
            
            this.totalCost     = 0;
            this.totalMarkup   = 0;
            this.totalRetail   = 0;
            this.totalBookings = 0;
            
            for (const key in topSupplierGraph) {
                this.pieLabels.push(key);
                this.markupArr.labels.push(key);

                let total = 0;
                let totalMarkup = 0;
                
                topSupplierGraph[key].forEach(item => {
                  total += parseFloat(item.TOTA_COST);
                  totalMarkup += parseFloat(item.MARKUP);
                  this.totalCost     += parseFloat(item.TOTA_COST);
                  this.totalMarkup   += parseFloat(item.MARKUP);
                  this.totalRetail   += parseFloat(item.TOTAL_RETAIL);
                  this.totalBookings += parseFloat(item.QTY_BOOKINGS);
                });
                tempArr.push(total) 
                tempArrMarkup.push(totalMarkup);
            }
            
            this.pieValues =[ {data: tempArr}]
            this.markupArr.values =[ {data: tempArrMarkup}]
            // [{ data: tempArrVal }]
            // .push({
            //     data:  tempArr,
            //     // label: key,
            // })

            let tempCostKpi = [];
            let counter = 0;
            // this.pieLabels =[];
            let tempArrVal = [];
            this.suppliersTopKpis.forEach( item => {
                tempArrVal.push(item.TOTA_COST);
                tempCostKpi.push({
                    data: [item.TOTA_COST,item.TOTAL_RETAIL, item.MARKUP],
                    label: item.Service,
                    backgroundColors: this.colors[counter],
                });
                counter++
            });
            // this.pieValues = [{ data: tempArrVal }]
            this.suppLabels2 = ['COST', 'RETAIL', 'MARKUP']
            this.suppliersTopKpis = tempCostKpi
            
        },
        
        validAccess() {
            if(this.codeAccess == "asuaire2022") {
                this.modalShow = false
                this.status = "done"
                localStorage.setItem('CDG', JSON.stringify(this.codeAccess))
            } else {
                this.errorCode = true
            }
        },

        groupArrBy (array, property){
            return array.reduce((grouped, element) => (
                {
                    ...grouped,
                    [element[property]]: [ ...(grouped[element[property]] || []), element]
                }), {}
            )
        }
    }
}
</script>